// types
import type { Nullable, Subject } from '@revolutionprep/types'

export const useScheduleSessionsV2Store = defineStore(
  'schedule-sessions',
  () => {
    // state
    const sessionSubject = ref<Nullable<Subject>>(null)
    const sessionSubjectId = ref<Nullable<number>>(null)
    const sessionDuration = ref<Nullable<number>>(null)
    const sessionFocus = ref<Nullable<string>>(null)
    const sessionFrequency = ref<Nullable<number>>(null)

    // actions
    function doResetScheduleSessionsV2Store () {
      sessionSubject.value = null
      sessionSubjectId.value = null
      sessionDuration.value = null
      sessionFocus.value = null
      sessionFrequency.value = null
    }

    return {
      doResetScheduleSessionsV2Store,
      sessionSubject,
      sessionSubjectId,
      sessionDuration,
      sessionFocus,
      sessionFrequency
    }
  }
)
